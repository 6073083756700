// Login.js
import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebase'; // Import Firebase auth instance
import { Link, useNavigate } from 'react-router-dom'; // For navigation to registration page
import styles from '../assets/styles/Login.module.css'; // Updated to use CSS Modules

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Logged in user:', userCredential.user);
      navigate('/document-library');
    } catch (error) {
      setError('Failed to log in. Please check your credentials.');
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginBox}>
        <h2 className={styles.loginHeading}>Log In</h2>
        <p className={styles.loginSubtext}>Login here using your username and password</p>
        <form onSubmit={handleLogin} className={styles.loginForm}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={styles.inputField}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={styles.inputField}
          />
          {error && <p className={styles.errorMessage}>{error}</p>}
          <button type="submit" className={styles.loginButton}>Log In</button>
        </form>
        <div className={styles.footerLinks}>
          <Link to="/forgot-password" className={styles.forgotPassword}>Forgot Password?</Link>
          {/* <Link to="/register" className={styles.signupButton}>Sign Up ✚</Link> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
