import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/MenuBar.css'; // Optional: Separate CSS for the menu if needed

const MenuBar = () => {
  return (
    <nav className="menu-bar">

      <ul>
      <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/messages">Messages</Link></li>
        

        
      </ul>
    </nav>
  );
};

export default MenuBar;