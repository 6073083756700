// src/components/About.js
import React from "react";
import '../assets/styles/About.css';


const About = () => {
  return (
    <div className="about-page-container">
             {/* Menu Bar */}
             <div className="menu-container">
      <a href="about" className="menu-item">About</a> 
      <a href="messages" className="menu-item">Messages</a> 
      <a href="/" className="menu-item">Home</a>  
</div>
      

      {/* About Section */}
      <section className="about-content p-6 bg-white shadow-md rounded mt-4">
        <h1 className="text-2xl font-bold mb-4">About Us</h1>
        <p className="text-gray-700 mb-6">
        Our Vision
        FCC exists to glorify God the Father as believers united in life in Jesus Christ who participate in the mission of His Church by the power of the Holy Spirit (Matt 28:18-20 & Acts 1:8).
        <h2>Our Mission</h2>
          <ul>
            <li>To provide oversight to the members & leaders of the association in pursuit of the vision.</li>
            <li>To execute biblical mandates of worship, evangelism, education, charity, and pastoral care.</li>
            <li>To take ownership of the great commission from Jesus Christ to the church.</li>
            <li>To always allow the influence of the Holy Spirit in every situation.</li>
            <li>To create an atmospheric environment for the fold ministry to flourish.</li>
            <li>To imitate and keep our eyes fixed on Christ, the author and perfecter of our faith.</li>
          </ul>
        </p>
      </section>

      {/* Footer */}
      <footer className="footer mt-10 p-4 bg-gray-200 text-center text-sm">
        <p className="text-gray-600">© 2024 Your Company Name. All rights reserved.</p>
        <div className="social-links flex justify-center space-x-4 mt-2">
          <a href="https://www.facebook.com" className="text-blue-600 hover:underline">
            Facebook
          </a>
          <a href="https://www.twitter.com" className="text-blue-500 hover:underline">
            Twitter
          </a>
          <a href="https://www.linkedin.com" className="text-blue-700 hover:underline">
            LinkedIn
          </a>
        </div>
      </footer>
    </div>
  );
};

export default About;
