// src/components/Home.js
import React, { useState, useEffect } from 'react';
import "../assets/styles/Home.css"; // Make sure this path points to your CSS file
import image1 from '../assets/images/Poster 1.jpg';
import image2 from '../assets/images/Poster 3.jpg';
import image3 from '../assets/images/group.jpg';
import image4 from '../assets/images/men.jpg';
import image5 from '../assets/images/warrior.jpg';
import image6 from '../assets/images/newspaper.jpg';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-modal';
import logo from '../assets/images/kogmi.jpg';
import image10 from '../assets/images/YHTMI Logo.jpg';
import image11 from '../assets/images/Eden Connect Logo.jpg';
import image12 from '../assets/images/FCC logo new.jpg';


const images = [image1, image2, image3, image4, image5, image6,]; // Array of images to display

const localizer = momentLocalizer(moment);

const Home = () => {
 
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
    const [events, setEvents] = useState([]);

  const CustomEvent = ({ event }) => (
    <span>
      <strong>{event.title}</strong>
      <br />
      <span>{event.start.toLocaleDateString()} - {event.description.substring(0, 20)}...</span> {/* Show part of description */}
    </span>
  );

   const handleEventClick = (event) => {
    setSelectedEvent(event); // Set the clicked event
  };

  const closeModal = () => {
    setSelectedEvent(null); //Close the modal
  };

 // Function to open the modal with the selected image
 const handleImageClick = (image) => {
  setSelectedImage(image);
};

// Function to close the modal
const handleCloseModal = () => {
  setSelectedImage(null);
};

useEffect(() => {
  // Replace this with your actual fetch logic if pulling from Firestore or other sources
  const eventList = [
    {
      title: 'KINGDOM MANDATE SERVICE',
      start: new Date(2024, 9, 24, 18, 30), // Month is 0-indexed in JavaScript (October is 9)
      end: new Date(2024, 9, 24, 21, 30),
      description: 'Worshipers(Corrie and Ruzaan(Life Bouy) FCC',
    },
    {
      title: ' KINGDOM ADVANCEMENT',
      start: new Date(2024, 11, 8, 18, 0), // Month is 0-indexed in JavaScript (Dec is 11)
      end: new Date(2024, 11, 8, 21, 0),
      description: 'YHTMI Apostolic centre, WAAIERTJIE CRECENT ROOSENDAL, DELFT, CAPE TOWN, RSA',
    },
      
    {
      title: 'Kingdom manifestation Annual conference',
      start: new Date(2024, 8, 25, 18, 0), // Month is 0-indexed in JavaScript (October is 9)
      end: new Date(2024, 8, 25, 18, 0),
      description: 'ANSE AUX PINS COMMUNITY CENTRE',
    },
    {
      title: 'Kingdom manifestation Annual conference',
      start: new Date(2024, 8, 26, 18, 30), // Month is 0-indexed in JavaScript (October is 9)
      end: new Date(2024, 8, 26, 21, 30),
      description: 'ANSE AUX PINS COMMUNITY CENTRE',
    },
    {
      title: 'Kingdom manifestation Annual conference',
      start: new Date(2024, 8, 27, 18, 30), // Month is 0-indexed in JavaScript (October is 9)
      end: new Date(2024, 8, 27, 21, 30),
      description: 'ANSE AUX PINS COMMUNITY CENTRE',
    },
    {
      title: 'Kingdom manifestation Annual conference',
      start: new Date(2024, 8, 28, 5, 0), // Month is 0-indexed in JavaScript (October is 9)
      end: new Date(2024, 8, 28, 5, 0),
      description: 'ANSE AUX PINS COMMUNITY CENTRE',
    },
    {
      title: 'PROPHETIC CONFERENCE',
      start: new Date(2024, 10, 23, 3,0), // Month is 0-indexed in JavaScript (October is 9)
      end: new Date(2024, 10, 23, 6, 30),
      description: 'STRANDFONTEIN AOG CHURCH',
    },
    {
      title: 'PROPHETIC CONFERENCE',
      start: new Date(2024, 10, 24, 4, 0), // Month is 0-indexed in JavaScript (October is 9)
      end: new Date(2024, 10, 24, 7, 30),
      description: 'STRANDFONTEIN AOG CHURCH',
    },
    // Add more events as needed...
  ];
  setEvents(eventList);
  }, []);

  return (
    <div className="home-container">
      {/* Menu Bar */}
      <div className="menu-container">
      <a href="about" className="menu-item">About</a> 
      <a href="messages" className="menu-item">Messages</a> 
      <a href="login" className="menu-item">Docs</a> 
      < a href="admin" className='menu-item'>Admin</a>

     

 
  
</div>

<div className="header-container">
  <div className="logo-container">
    <img src={logo} alt="Logo" className="logo" />
  </div>
  <h1 className="main-heading">Welcome to the Kingdom of GOD Ministries International</h1>
</div>

{/* Horizontal Boxes for Images */}
<div className="image-boxes-container">
  <div className="image-box">
    <img src={image10} alt="Image 1" className="box-image" />
  </div>
  <div className="image-box">
    <img src={image11} alt="Image 2" className="box-image" />
  </div>
  <div className="image-box">
    <img src={image12} alt="Image 3" className="box-image" />
  </div>
</div>

{/* Second Heading for Events */}
<h2 className="events-heading">Upcoming Events</h2>

      {/* Gallery Section */}
      <div className="gallery">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Gallery ${index}`}
            className="gallery-image"
            onClick={() => handleImageClick(image)}
          />
        ))}
      </div>

      {/* Modal to display selected image */}
      {selectedImage && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content">
            <img src={selectedImage} alt="Enlarged view" className="modal-image" />
          </div>
        </div>
      )}

      {/* Calendar Section */}
      <div className="calendar-section mt-4">
        <h2 className="text-lg font-medium mb-2">Upcoming Events</h2>
        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600 }}
          defaultView="month"
          components={{
          event: CustomEvent, //
           }}
           onSelectEvent={handleEventClick} // Click handler for events
        />
      </div>

       {/* Modal for showing event description */}
       {selectedEvent && (
        <Modal
          isOpen={!!selectedEvent}
          onRequestClose={closeModal}
          contentLabel="Event Description"
        >
          <h2>{selectedEvent.title}</h2>
          <p>{selectedEvent.description}</p>
          <button onClick={closeModal}>Close</button>
        </Modal>
      )}

      {/* Vision and Mission */}
      <div className="vision-mission">
        <div className="vision">
          <h2>Our Vision</h2>
          <p>FCC exists to glorify God the Father as believers united in life in Jesus Christ...</p>
        </div>
        <div className="mission">
          <h2>Our Mission</h2>
          <ul>
            <li>To provide oversight to the members & leaders of the association...</li>
            <li>To provide oversight to the members & leaders of the association in pursuit of the vision.</li> 
<li>To execute biblical mandates of worship, evangelism, education, charity and pastoral care.</li>
<li>Preserve the historic foundational biblical truths and principals of sound doctrine. </li>
<li>To take ownership of the great commission from Jesus Christ to the church.</li>
<li>To always allow the influence of the Holy Spirit in every situation.</li>
<li>To create a atmospheric environment for the fold ministry to florist.</li>
<li>7. To imitate and keep our eyes fixed on Christ the author and perfecter of our faith.</li>
            {/* Add other mission points as needed */}
          </ul>
        </div>
      </div>

      {/* Footer */}
      <footer className="footer">
        <div className="social-links">
          <a href="https://www.facebook.com/groups/567982000436359" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        </div>
        <div className="footer-info">
          <p>&copy; 2024 Your Company. All Rights Reserved.</p>
          <p>Contact us at: info@yourcompany.com</p>
        </div>
      </footer>
    </div>
  );
};

export default Home; // Correctly placed export
