import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../assets/styles/react-big-calendar.css'; // Make sure your CSS path is correct
import { db } from '../firebase/firebase'; // Import your Firebase configuration
import { collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import MenuBar from './MenuBar';

// Configure the localizer by providing the moment (date library)
const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [user, setUser] = useState(null); // State to track authenticated user

  // Monitor authentication state
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        console.log('User is authenticated');
        setUser(currentUser); // Set the authenticated user
      } else {
        console.log('User is not authenticated');
        setUser(null);
      }
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Fetch events from Firestore
    const fetchEvents = async () => {
      try {
        const eventCollection = collection(db, 'events'); // Ensure 'events' is the correct collection name
        const eventSnapshot = await getDocs(eventCollection);
        const eventList = eventSnapshot.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().title,
          start: new Date(doc.data().start), // Fetch start date from Firestore
          end: new Date(doc.data().end),     // Fetch end date from Firestore
          description: doc.data().description,
        }));
        setEvents(eventList);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  // Handle adding a new event
  const handleAddEvent = async () => {
    const title = prompt('Enter event title:');
    const startDate = prompt('Enter start date (YYYY-MM-DD):');
    const endDate = prompt('Enter end date (YYYY-MM-DD):');

    if (title && startDate && endDate) {
      try {
        const newEvent = {
          title,
          start: new Date(startDate),
          end: new Date(endDate),
          description: 'New event', // Add additional details if needed
          createdAt: serverTimestamp(),
        };

        // Add the event to Firestore
        await addDoc(collection(db, 'events'), newEvent);
        setEvents([...events, newEvent]); // Update local state with the new event
        alert('Event added successfully!');
      } catch (error) {
        console.error('Error adding event:', error);
        alert('Error adding event. Please try again.');
      }
    } else {
      alert('Please provide a valid title and dates.');
    }
  };

  return (
    <div className="calendar-page">
      <MenuBar />
      <h2>Event Calendar</h2>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, margin: '50px' }}
        selectable
      />
      {user && (
        <button
          onClick={handleAddEvent}
          className="add-event-button bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Event
        </button>
      )}
    </div>
  );
};

export default MyCalendar;
